import React, { createContext, useContext, useState, useEffect } from 'react';
import {io} from "socket.io-client";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
  
    useEffect(() => {
        const socketC = io(process.env.REACT_APP_SOCKET_URL, {
          query: {
            type: "admin",
            token: Cookies.get("token"),
          },
        });
        setSocket(socketC);
      // Temizleme fonksiyonu
      return () => {
        console.log("Disconnecting socket...");
        socketC.disconnect();
        console.log("Socket disconnected");
      };
      }, []);
    
      useEffect(() => {
        if (socket) {
          socket.on("connect", () => {
            toast("Sunucuya başarıyla bağlandı", { icon: "🔋" });
            // setIsSocketConnected(true);
          });
    
          socket.on("disconnect", () => {
            toast("Sunucu ile bağlantı kesildi, anlık işlemleri göremeyebilirsiniz", { icon: "🪫" });
            // setIsSocketConnected(false);
          });
    
          return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off("pong");
          };
        }
      }, [socket]);
    
  
    return (
      <SocketContext.Provider value={socket}>
        {children}
      </SocketContext.Provider>
    );
  };

export const useSocket = () => useContext(SocketContext);
